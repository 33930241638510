import { getAuthClient } from '~/utils/apiClient'
import { SSRAuthToken } from '~/utils/ssr'

import { parseFactory } from './model'
import { ReviewSummarySchema, ReviewSummaryType, ReviewType } from './model/review'

const reviewSummaryParser = parseFactory(ReviewSummarySchema, 'ReviewSummary')

const boardTestData: ReviewSummaryType = {
  averageRating: 4.6,
  totalReviews: 5,
  after: 3,
  reviews: [
    {
      id: 'ABC',
      text: 'Hello World 2222',
      score: 5,
      created: '2024-05-03T03:27:11Z',
      author: 'andrew scales',
      username: 'andrew',
      image: 'https://cataas.com/cat',
    },
    {
      id: 'DEF',
      text: '',
      score: 3,
      created: '2024-04-30T12:16:51Z',
      author: 'andrew scales',
      username: 'andrew',
      image: 'https://cataas.com/cat',
    },
    {
      id: 'HIJ',
      text: 'Hello World',
      score: 5,
      created: '2024-04-29T13:16:51Z',
      author: 'andrew scales',
      username: 'andrew',
      image: 'https://cataas.com/cat',
    },
  ],
}

const sellerTestData: ReviewSummaryType = {
  averageRating: 2.0,
  totalReviews: 1,
  after: 3,
  reviews: [
    {
      id: 'KLM',
      text: 'Hello World',
      score: 2,
      created: '2024-04-30T08:16:51Z',
      author: 'andrew scales',
      username: 'andrew',
      image: 'https://cataas.com/cat',
    },
  ],
}

export const fetchReviewsForBoard = async (
  boardToken: string,
  username: string,
  after: number = 3,
  auth?: SSRAuthToken
): Promise<ReviewSummaryType> => {
  const apiClient = getAuthClient(auth)
  try {
    const res = await apiClient.get(`/reviews/search`, {
      params: {
        board: boardToken,
        seller: username,
        after: after,
      },
    })

    return reviewSummaryParser(res.data)
  } catch {
    return { averageRating: 0, totalReviews: 0, after: 0, reviews: [] }
  }
}

export const fetchReviewsForSeller = async (
  username: string,
  after: number = 3,
  auth?: SSRAuthToken
): Promise<ReviewSummaryType> => {
  const apiClient = getAuthClient(auth)
  try {
    const res = await apiClient.get(`/reviews/search`, {
      params: {
        seller: username.replace('@', ''),
        after,
      },
    })

    return reviewSummaryParser(res.data)
  } catch {
    return { averageRating: null, totalReviews: null, after: 0, reviews: [] }
  }
}

export const putHideReview = async (reviewUid: string, auth?: SSRAuthToken) => {
  const apiClient = getAuthClient(auth)
  try {
    const res = await apiClient.put<ReviewType>(`/reviews/${reviewUid}/hide`)
    return res.data
  } catch {
    return null
  }
}
