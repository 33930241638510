import { Flex } from '@mantine/core'
import { IconChevronUp } from '@tabler/icons'

import { Typography } from './Typography'

export const ShowLessText = () => (
  <Flex
    sx={{ cursor: 'pointer' }}
    align="center"
    gap={1}
  >
    <Typography
      variant="button_small"
      rawLink
      span
      underline
    >
      Read less
    </Typography>
    <IconChevronUp size={18} />
  </Flex>
)
