import { z } from 'zod'

export const ReviewSchema = z.object({
  id: z.string(),
  text: z.string().nullish(),
  score: z.number(),
  created: z.string(),
  deleted: z.string().nullish(),
  author: z.string(),
  username: z.string(),
  image: z.string().nullish(),
})
export type ReviewType = z.infer<typeof ReviewSchema>

export const ReviewSummarySchema = z.object({
  averageRating: z.number().nullish(),
  totalReviews: z.number().nullish(),
  after: z.number(),
  reviews: z.array(ReviewSchema),
})
export type ReviewSummaryType = z.infer<typeof ReviewSummarySchema>
