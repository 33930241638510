import {
  CreateServiceSessionRequest,
  StripeSessionDetails,
  StripeSessionSchema,
  parseFactory,
} from '~/endpoints/model'
import { getAuthClient } from '~/utils/apiClient'

const stripeSessionParser = parseFactory(StripeSessionSchema, 'StripeSession')

export const createStripeBoardPaymentSession = async (sessionData: StripeSessionDetails) => {
  const httpClient = getAuthClient()
  const res = await httpClient.post('/payments/session', sessionData)
  return stripeSessionParser(res.data)
}

export const createStripeServicePaymentSession = async (data: CreateServiceSessionRequest) => {
  const httpClient = getAuthClient()
  const res = await httpClient.post('/payments/service', data)
  return stripeSessionParser(res.data)
}
