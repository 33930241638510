import { Flex } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons'

import { Typography } from './Typography'

export const ShowMoreText = () => (
  <Flex
    sx={{ cursor: 'pointer' }}
    align="center"
    gap={1}
  >
    <Typography
      variant="button_small"
      rawLink
      span
      underline
    >
      Read more
    </Typography>
    <IconChevronDown
      style={{ marginBottom: -3 }}
      size={18}
    />
  </Flex>
)
