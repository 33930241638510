import { Box, Card, Flex, Group, Rating, Stack } from '@mantine/core'
import { IconChevronDown, IconChevronUp, IconEye, IconEyeOff } from '@tabler/icons'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import Link from 'next/link'
import React, { useState } from 'react'

import { useCurrentAuthState } from '~/context'
import { ReviewSummaryType } from '~/endpoints/model/review'
import { fetchReviewsForBoard, fetchReviewsForSeller, putHideReview } from '~/endpoints/review'
import { useAuth } from '~/hooks'

import { ThatchAvatar } from '../shared/ThatchAvatarDynamic'
import { getImageUrl } from '../shared/image/helpers'
import { Typography } from '../shared/text/Typography'
import { StarEmpty, StarFilled } from './ReviewIcons'

interface ReviewProps {
  uid: string
  username: string
  authorName: string
  reviewText?: string | null
  date: string
  rating: number
  showFullReview: boolean
  onReadMore: () => void
  onHide: (uid: string) => void
  photo?: string | null
  admin: boolean
  deleted: boolean
bg?: string
}

// ReviewCard component
const TRUNCATE_LGTH = 200
const ReviewCard: React.FC<ReviewProps> = ({
  uid,
  username,
  authorName,
  reviewText,
  date,
  rating,
  onReadMore,
  onHide,
  showFullReview,
  photo,
  admin,
  deleted,
  bg = 'appPaper.0',
}) => {
  return (
    <Card
      bg={bg}
      p="md"
      style={{ maxWidth: '100%', margin: 'auto', width: '100%' }}
    >
      <Group
        mb={12}
        spacing="sm"
        position="apart"
      >
        <Rating
          readOnly
          fractions={2}
          value={rating}
          emptySymbol={StarEmpty}
          fullSymbol={<StarFilled />}
        />
        {admin && (
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => onHide(uid)}
          >
            {deleted ? <IconEyeOff /> : <IconEye />}
          </Box>
        )}
      </Group>
      {reviewText && (
        <>
          <Typography variant="body3">
            &quot;
            {reviewText.length > TRUNCATE_LGTH
              ? showFullReview
                ? reviewText
                : `${reviewText.substring(0, TRUNCATE_LGTH)}...`
              : reviewText}
            &quot;
          </Typography>
          {reviewText.length > TRUNCATE_LGTH && (
            <Flex
              dir="row"
              align="center"
              gap={2}
              mt={12}
            >
              <Typography
                sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }}
                onClick={onReadMore}
                variant="button_small"
              >
                Read {showFullReview ? 'less' : 'more'}
              </Typography>
              {showFullReview ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
            </Flex>
          )}
        </>
      )}

      <Group
        mt={20}
        position="apart"
      >
        <Group spacing={12}>
          <ThatchAvatar
            url={getImageUrl({ src: photo, width: 40 })}
            desktopSize={28}
            mobileSize={28}
          />
          <Link href={`/@${username}`}>
            <Typography
              sx={{ fontSize: '16px !important' }}
              variant="body3SerifItalic"
            >
              {authorName}
            </Typography>
          </Link>
        </Group>
        <Typography
          weight={500}
          variant="caption"
          color="appPlaceholder.0"
        >
          {format(new Date(date), 'MMM yyyy')}
        </Typography>
      </Group>
    </Card>
  )
}

interface ReviewSystemProps {
  boardToken?: string
  sellerUsername: string
  reviews: ReviewSummaryType
}

const ReviewSystemSeller: React.FC<ReviewSystemProps> = ({ reviews, sellerUsername }) => {
  const [limit, setLimit] = useState(3)
  const user = useAuth()
  const { userProfile } = useCurrentAuthState()
  const _prefetchQuery = useQuery({
    queryKey: ['reviews', 'seller', sellerUsername, limit + 3],
    queryFn: () => fetchReviewsForSeller(sellerUsername, limit + 3, user.getIdToken),
  })
  const reviewsQuery = useQuery({
    queryKey: ['reviews', 'seller', sellerUsername, limit],
    queryFn: () => fetchReviewsForSeller(sellerUsername, limit, user.getIdToken),
    initialData: reviews,
    enabled: limit > 3,
    // placeholderData: keepPreviousData,
  })

  const qc = useQueryClient()
  const hide = useMutation({
    mutationFn: (uid: string) => putHideReview(uid),
    onSuccess: (res, vars, ctx) => {
      const data: ReviewSummaryType | undefined = qc.getQueryData([
        'reviews',
        'seller',
        sellerUsername,
        limit,
      ])
      if (data != null && res) {
        data.reviews = data.reviews.map(r => (r.id == vars ? res : r))
      }
      qc.setQueryData(['reviews', 'seller', sellerUsername, limit], data)
    },
  })

  return (
    <ReviewSystemImpl
      reviews={reviewsQuery.data}
      admin={userProfile?.admin ?? false}
      sellerUsername={sellerUsername}
      setLimit={setLimit}
      onHide={hide.mutate}
      limit={limit}
    />
  )
}

const ReviewSystemBoard: React.FC<ReviewSystemProps & { boardToken: string }> = ({
  reviews,
  sellerUsername,
  boardToken,
}) => {
  const user = useAuth()
  const { userProfile } = useCurrentAuthState()

  const [limit, setLimit] = useState(3)
  const _prefetchQuery = useQuery({
    queryKey: ['reviews', 'board', sellerUsername, boardToken, limit + 3],
    queryFn: () => fetchReviewsForBoard(boardToken, sellerUsername, limit + 3, user.getIdToken),
    networkMode: 'offlineFirst',
  })
  const reviewsQuery = useQuery({
    queryKey: ['reviews', 'board', sellerUsername, boardToken, limit],
    queryFn: () => fetchReviewsForBoard(boardToken, sellerUsername, limit, user.getIdToken),
    initialData: reviews,
    enabled: limit > 3,
  })

  const qc = useQueryClient()
  const hide = useMutation({
    mutationFn: (uid: string) => putHideReview(uid, user.getIdToken),
    onSuccess: (res, vars) => {
      const data: ReviewSummaryType | undefined = qc.getQueryData([
        'reviews',
        'board',
        sellerUsername,
        boardToken,
        limit,
      ])
      if (data != null && res) {
        data.reviews = data.reviews.map(r => (r.id == vars ? res : r))
      }
      qc.setQueryData(['reviews', 'board', sellerUsername, boardToken, limit], data)
    },
  })

  return (
    <ReviewSystemImpl
      reviews={reviewsQuery.data ?? { after: 0, reviews: [], averageRating: null, totalReviews: 0 }}
      admin={userProfile?.admin ?? false}
      sellerUsername={sellerUsername}
      boardToken={boardToken}
      setLimit={setLimit}
      onHide={hide.mutate}
      limit={limit}
      cardBg='appWhite.0'
    />
  )
}

// ReviewSystem component
const ReviewSystemImpl: React.FC<
  ReviewSystemProps & {
    setLimit: (limit: number) => void
    onHide: (uid: string) => void
    limit: number
    admin: boolean
    cardBg?: string
  }
> = ({ reviews, sellerUsername, boardToken, setLimit, limit, admin, onHide, cardBg }) => {
  const [activeReviewIndex, setActiveReviewIndex] = useState<number | null>(null)
  const [openedReviews, setOpenedReviews] = useState<string[]>([])

  const handleSetOpen = (id: string) => {
    if (openedReviews.includes(id)) {
      setOpenedReviews(openedReviews.filter(r => r !== id))
    } else {
      setOpenedReviews(openedReviews.concat(id))
    }
  }

  if (!reviews || reviews?.totalReviews == 0) {
    return <Box></Box>
  }

  return (
    <Stack mb={48}>
      <Box mb={21}>
        <Group spacing={12}>
          <StarFilled
            fill="#000"
            size="24"
          />
          <Typography variant="body1">
            {reviews.averageRating?.toFixed(1) ?? 0}{' '}
            <Typography
              span
              color="appPlaceholder.0"
            >
              ({reviews.totalReviews} Ratings & Reviews)
            </Typography>
          </Typography>
        </Group>
      </Box>
      {reviews.reviews.map((review, index) => (
        <ReviewCard
          key={review.id}
          uid={review.id}
          username={review.username}
          authorName={review.author}
          reviewText={review.text}
          date={review.created}
          rating={review.score}
          showFullReview={openedReviews.includes(review.id)}
          photo={review.image}
          onReadMore={() => handleSetOpen(review.id)}
          onHide={onHide}
          admin={admin}
          deleted={review.deleted != null}
          bg={cardBg}
        />
      ))}
      {(reviews.totalReviews ?? 0) > limit && (
        <Flex
          dir="row"
          align="center"
        >
          <Typography
            onClick={() => setLimit(limit + 3)}
            variant="body2"
          >
            <Box
              component="span"
              mr={4}
              sx={{ textDecoration: 'underline', ':hover': { cursor: 'pointer' } }}
            >
              Show more
            </Box>
          </Typography>
          <IconChevronDown size={18} />
        </Flex>
      )}
      {reviews.totalReviews && reviews.totalReviews <= limit && reviews.totalReviews > 3 && (
        <Flex
          dir="row"
          align="center"
        >
          <Typography
            onClick={() => setLimit(3)}
            variant="body2"
          >
            <Box
              component="span"
              mr={4}
              sx={{ textDecoration: 'underline', ':hover': { cursor: 'pointer' } }}
            >
              Show less
            </Box>
          </Typography>
          <IconChevronUp size={18} />
        </Flex>
      )}
    </Stack>
  )
}

export { ReviewSystemSeller, ReviewSystemBoard }
