import { Box, Flex } from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { useState } from 'react'

import { Typography } from '~/components/shared/text/Typography'
import { useScreenSize } from '~/hooks'

// eslint-disable-next-line import/no-cycle
// import { Title } from './ServiceDetails'

export type FrequentlyAskedQuestion = {
    question: string
    answer: string
}

export const FrequentlyAskedQuestionsSection = ({ questions }: { questions: FrequentlyAskedQuestion[] }) => {
  const { isMobileScreen } = useScreenSize()
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number | null>(0)

  const onSelectQuestion = (index: number) => {
    if (selectedQuestionIndex === index) {
      setSelectedQuestionIndex(null)
    } else {
      setSelectedQuestionIndex(index)
    }
  }

  return (
    <Flex
      direction="column"
      gap={16}
    >
      {/* <Title
        value="Common"
        italic="Questions"
      /> */}
      <Box>
        {questions
          .map(({ question, answer }, idx) => (
            <Flex
              onClick={() => onSelectQuestion(idx)}
              key={`question-${idx}`}
              py={32}
              gap={24}
              direction="column"
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <Flex
                justify="space-between"
                align="center"
                gap={24}
              >
                <Typography
                  variant="body1"
                  sx={isMobileScreen ? { fontSize: '20px !important' } : {}}
                >
                  {question}
                </Typography>
                <IconPlus />
              </Flex>
              {selectedQuestionIndex === idx && (
                <Typography
                  variant="body3"
                  color="appPlaceholder.0"
                  className='whitespace-pre-line'
                >
                  {answer}
                </Typography>
              )}
            </Flex>
          ))}
      </Box>
    </Flex>
  )
}