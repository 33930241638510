import { Box, Flex, Sx } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'

import { Typography, Variant } from '~/components/shared/text/Typography'

interface TextReadMoreOrLessProps {
  id: string
  text: string | ReactNode
  readMore: ReactNode
  readLess: ReactNode
  linesLimit: number
  variant?: Variant
  enabled: boolean
  parentSetShowMore?: (value: boolean) => void
  parentShowMore?: boolean
  containerSx?: Sx
  textSx?: Sx
}

export const TextReadMoreOrLess: React.FC<TextReadMoreOrLessProps> = ({
  id,
  text,
  textSx,
  readMore,
  readLess,
  linesLimit,
  variant,
  containerSx,
  parentSetShowMore,
  parentShowMore,
  enabled,
}) => {
  const [isEnable, setIsEnable] = useState(enabled)
  const [showMore, setShowMore] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (parentSetShowMore) {
      parentSetShowMore(!parentShowMore)
    } else {
      setShowMore(!showMore)
    }
  }

  useEffect(() => {
    if (isEnable) {
      const textElement = document.getElementById(id)

      if (textElement) {
        const height = textElement.clientHeight ?? 0
        const lineHeight =
          parseFloat(window.getComputedStyle(textElement, null).getPropertyValue('line-height')) ??
          1
        const lines = Math.floor(height / lineHeight)

        if (lines > linesLimit) {
          if (parentSetShowMore) {
            parentSetShowMore(true)
          } else {
            setShowMore(true)
          }
        } else {
          setIsEnable(false)
        }
      }
    }
  }, [id, isEnable, linesLimit])

  return (
    <Box sx={containerSx}>
      <Typography
        id={id}
        variant={variant}
        sx={{ whiteSpace: 'pre-wrap', ...textSx }}
        lineClamp={parentShowMore || showMore ? linesLimit : undefined}
      >
        {text}
      </Typography>

      {isEnable && (
        <Flex
          align="end"
          mt={16}
          onClick={handleClick}
        >
          {parentShowMore || showMore ? readMore : readLess}
        </Flex>
      )}
    </Box>
  )
}
